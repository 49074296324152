import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Institute } from '@core/model/Institute.model';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginDto } from './dto/login.dto';
import { SignupDto } from './dto/signup.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: FlamingoHttpService,
  ) {
  }

  login(dto: LoginDto): Observable<any> {
    return this.http.post('/auth/login', dto);
  }

  registerAccount(dto: SignupDto): Observable<any> {
    return this.http.post('/auth/signup-company', dto);
  }

  signupAccountant(dto: SignupDto): Observable<any> {
    return this.http.post('/auth/signup-accountant', dto);
  }

  resetEmail(username: string): Observable<any> {
    return this.http.post('/auth/reset-code', { username });
  }

  resetCompanyMainUserCode(taxCode: string): Observable<any> {
    return this.http.post('/auth/reset-company-main-user-code', { taxCode });
  }

  checkCode(codeDto: { username: string, code: string }): Observable<any> {
    return this.http.post('/auth/check-code', codeDto);
  }

  activate(activateDto: { username: string, code: string, password: string }): Observable<any> {
    return this.http.post('/auth/activate', activateDto);
  }

  acceptInvitation(id: string | null): Observable<void> {
    if (!id) {
      return of();
    }
    return this.http.post<void>(`/auth/invitation/${id}`);
  }

  getSignupInfo(code: string): Observable<any> {
    if (!code) {
      return of({});
    }
    const params = new HttpParams().appendAll({ code });
    return this.http.get<any>(`/auth/signup-info`, params)
      .pipe(
        catchError(_ => of({})),
      );
  }

  getInstitutes(subtype: string): Observable<Institute[]> {
    const params = new HttpParams().appendAll({ subtype });
    return this.http.get<Institute[]>(`/auth/accountant-institute`, params);
  }
}
