import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaginatedList } from '@default-admin/model/paginated-list.model';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Company } from '@x/model/company.model';
import { SubscriptionModel } from '@x/model/subscription.model';
import { BehaviorSubject, Observable, skip, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MainService {

  private _companies$ = new BehaviorSubject<Company[]>([]);
  public companies$ = this._companies$.asObservable();
  private _company$ = new BehaviorSubject<Company | undefined>(undefined);
  public company$ = this._company$.asObservable();
  private _subscription$ = new BehaviorSubject<SubscriptionModel | undefined>(undefined);
  public subscription$ = this._subscription$.asObservable();
  private _loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loader$: Observable<boolean> = this._loader$.asObservable();

  constructor(
    router: Router,
    private http: FlamingoHttpService,
  ) {
    this.company$
      .pipe(
        filter((company: Company | undefined): company is Company => company !== undefined),
        distinctUntilChanged(),
        switchMap(company => this.getFirstSubscription(company)),
        tap(subscription => this.subscription = subscription),
        skip(1),
        switchMap(_ => router.navigate(['/'], { queryParams: { t: Date.now() } })),
      )
      .subscribe();

    this.companies$
      .pipe(
        filter(companies => !!companies.length),
        tap(companies => this.company = companies[0]),
      )
      .subscribe();
  }

  get companies(): Company[] {
    return this._companies$.value;
  }

  set companies(value: Company[]) {
    this._companies$.next(value);
  }

  get company(): Company | undefined {
    return this._company$.value;
  }

  set company(value: Company) {
    this._company$.next(value);
  }

  get subscription(): SubscriptionModel | undefined {
    return this._subscription$.value;
  }

  set subscription(value: SubscriptionModel | undefined) {
    this._subscription$.next(value);
  }

  get loader(): boolean {
    return this._loader$.value;
  }

  set loader(show: boolean) {
    this._loader$.next(show);
  }

  private getFirstSubscription(company: Company): Observable<SubscriptionModel | undefined> {
    const params = new HttpParams().appendAll({ size: 1 });
    return this.http.get<PaginatedList<SubscriptionModel>>(`/companies/${company?.id}/subscriptions`, params)
      .pipe(
        map(res => this.subscription = res.content[0] || undefined),
      );
  }

}
