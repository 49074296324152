import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export function initializeFlamingo(): () => Observable<any> {
  return () => {
    return of()
      .pipe(
        tap(_ => console.log('Startup Flamingo Services')),
      );
  };
}
