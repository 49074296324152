import { Inject, Injectable } from '@angular/core';
import { FlamingoHttpService } from '@flamingo/service/flamingo-http.service';
import { Observable } from 'rxjs';
import { FLAMINGO_APP_CONFIG, FlamingoAppConfig } from '../flamingo.config';

export enum StorageMode {
  session,
  local,
  none
}

export enum TokenType {
  basic,
  bearer
}

@Injectable({
  providedIn: 'root',
})
export class FlamingoAuthService {
  private storagePrefix: string;
  private storage: { [key: string]: any } = {};
  private currentStorageMode: StorageMode = StorageMode.session;

  constructor(
    private http: FlamingoHttpService,
    @Inject(FLAMINGO_APP_CONFIG) config?: FlamingoAppConfig,
  ) {
    this.storagePrefix = config?.storagePrefix ? config?.storagePrefix : 'flamingo_';
  }

  get hasAuthorizationToken(): boolean {
    return !!this.authorizationToken;
  }

  get authorizationToken(): string | null {
    return this.getToken('authorization');
  }

  get hasRefreshToken(): boolean {
    return !!this.refreshToken;
  }

  get refreshToken(): string | null {
    return this.getToken('refresh_token');
  }

  setStorageMode(storageMode: StorageMode): void {
    this.currentStorageMode = storageMode;
  }

  getToken(tokenId: string): string | null {
    if (this.storage[this.storagePrefix + tokenId]) {
      this.currentStorageMode = StorageMode.none;
      return this.storage[this.storagePrefix + tokenId];
    }
    if (sessionStorage.getItem(this.storagePrefix + tokenId)) {
      this.currentStorageMode = StorageMode.session;
      return sessionStorage.getItem(this.storagePrefix + tokenId);
    }
    if (localStorage.getItem(this.storagePrefix + tokenId)) {
      this.currentStorageMode = StorageMode.local;
      return localStorage.getItem(this.storagePrefix + tokenId);
    }
    return null;
  }

  setAuthorizationToken(value: string, type: TokenType): void {
    this.setToken('authorization', value, type);
  }

  setRefreshToken(value: string): void {
    this.setToken('refresh_token', value);
  }

  public generateBasicToken(username: string, password: string): string {
    return btoa(`${username}:${password}`);
  }

  public removeAuthorizationToken(): void {
    delete this.storage[this.storagePrefix + 'authorization'];
    sessionStorage.removeItem(this.storagePrefix + 'authorization');
    localStorage.removeItem(this.storagePrefix + 'authorization');
  }

  public logout(): void {
    Object.keys(this.storage).forEach(key => delete this.storage[key]);

    Object.entries(sessionStorage)
      .map(x => x[0])
      .filter(x => x.substring(0, this.storagePrefix.length) === this.storagePrefix)
      .map(x => sessionStorage.removeItem(x));

    Object.entries(localStorage)
      .map(x => x[0])
      .filter(x => x.substring(0, this.storagePrefix.length) === this.storagePrefix)
      .map(x => localStorage.removeItem(x));
  }

  accessToken(): Observable<any> {
    return this.http.post('/auth/access_token', {
      grant_type: 'refresh_token',
      refresh_token: this.refreshToken,
    });
  }

  private setToken(tokenId: string, value: string, type?: TokenType): void {
    this.clearStorage(tokenId);

    switch (type) {
      case TokenType.basic:
        value = 'Basic ' + value;
        break;
      case TokenType.bearer:
        value = 'Bearer ' + value;
        break;
      default:
        break;
    }
    this.store(value, tokenId);
  }

  private store(value: string, suffix: string): void {
    if (this.currentStorageMode === StorageMode.session) {
      sessionStorage.setItem(this.storagePrefix + suffix, value);
    } else if (this.currentStorageMode === StorageMode.local) {
      localStorage.setItem(this.storagePrefix + suffix, value);
    } else {
      this.storage[this.storagePrefix + suffix] = value;
    }
  }

  private clearStorage(tokenId: string): void {
    delete this.storage[this.storagePrefix + tokenId];
    sessionStorage.removeItem(this.storagePrefix + tokenId);
    localStorage.removeItem(this.storagePrefix + tokenId);
  }
}
