import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _environment$: BehaviorSubject<'DEV' | 'TEST' | 'PROD'> = new BehaviorSubject<'DEV' | 'TEST' | 'PROD'>('PROD');
  environment$ = this._environment$.asObservable();

  constructor() {
  }

  set environment(value: 'DEV' | 'TEST' | 'PROD') {
    this._environment$.next(value);
  }
}
